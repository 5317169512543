import * as React from 'jsx-dom';
import {prop, listen, memoize} from '@exadel/esl/modules/esl-utils/decorators';

import {BaseModal} from 'core/base-modal';
import {i18nLabel} from 'core/helpers/config';

import type {ESLToggleableActionParams} from '@exadel/esl/modules/esl-toggleable/core';
import type {HPEFormItem} from 'hpe-forms/hpe-form-item';

export class ModalForm extends BaseModal {
  public static is = 'hpe-modal-form';

  public static DEFAULT_FORM_TYPE = 'email';
  public static FORM_TYPES: Record<string, () => Promise<ModalFormControllerFactory>> = {
    email: () => import('hpe-forms/email-sales-form'),
    callback: () => import('hpe-forms/callback-form.modal')
  };

  static open(params: ModalFormParams = {form: { type: ModalForm.DEFAULT_FORM_TYPE }}): void {
    ModalForm.instance.show(params);
  }

  @memoize()
  static get instance(): ModalForm {
    return ModalForm.create();
  }

  @prop(true) public autoInject: boolean;

  protected controller: ModalFormController;

  @memoize()
  protected get $container(): HTMLElement {
    return (<div className="base-modal-container">
      <button type="button"
              className="close-btn icon-nav-close-menu"
              aria-label={i18nLabel('Close')}
              title={i18nLabel('Close')}
              data-modal-close/>
      {this.$spinner}
      {this.$content}
    </div>) as HTMLElement;
  }

  @memoize()
  protected get $spinner(): HTMLElement {
    return (<div className="hpe-spinner fade in hidden"/>) as HTMLElement;
  }

  @memoize()
  protected get $content(): HTMLElement {
    return (<div className="form-modal-content"/>) as HTMLElement;
  }

  @memoize()
  protected get $errorMessage(): HTMLElement {
    const errorMessage = document.createElement('div');
    errorMessage.classList.add('error-message', 'body-copy', 'typo4');
    errorMessage.innerText = i18nLabel('Something went wrong. Please try again later.');

    return errorMessage;
  }

  protected connectedCallback(): void {
    super.connectedCallback();
    this.$$cls(BaseModal.is, true);
    this.appendChild(this.$container);
  }

  protected async onShow(params: ModalFormParams): Promise<void> {
    super.onShow(params);
    const config = this.normalizeConfig(params);
    if (!this.controller || !this.controller.isDescribedBy(config)) {
      await this.reloadContent(config);
    }
    this.$focusables[0]?.focus();
  }

  protected onHide(params: ESLToggleableActionParams = {}) {
    super.onHide(params);
    if (this.$content) {
      const $fields = [...this.$content.querySelectorAll('hpe-form-item.has-error')];
      $fields.forEach(($field: HPEFormItem) => $field.reset());
    }
  }

  protected normalizeConfig(params: ModalFormParams): ModalFormConfig {
    const config: ModalFormConfig = Object.assign({}, params.form || {}) as ModalFormConfig;
    if (!ModalForm.FORM_TYPES[config.type]) config.type = ModalForm.DEFAULT_FORM_TYPE;
    return config;
  }

  protected async reloadContent(params: ModalFormConfig): Promise<void> {
    this.$spinner.classList.remove('hidden');
    this.controller?.destroy();
    this.$content.innerHTML = '';
    this.controller = null;

    try {
      const factory = await ModalForm.FORM_TYPES[params.type]();
      this.controller = factory.createForm(this.$content, params);
    } catch {
      this.showError();
    }
  }

  protected showError(): void {
    this.$content.innerHTML = '';
    this.$content.appendChild(this.$errorMessage);
  }

  protected destroyController(): void {
    this.controller.destroy();
    this.controller = null;
  }

  // Catch event of form complete
  @listen('hpe:leadgen:onAfterFormLoad')
  public onFormReady(): void {
    this.$spinner.classList.add('hidden');
  }

  // Catch form error
  @listen('hpe:leadgen:onFormLoadError')
  public onFormError(): void {
    this.showError();
    this.$spinner.classList.add('hidden');
    this.destroyController();
  }

  @listen('hpe:leadgen:onSubmitSuccessful')
  public onSubmitSuccessful(): void {
     this.destroyController();
  }

  @listen('hpe:leadgen:form:submitFailed')
  public onSubmitError(): void {
    this.destroyController();
  }
}

export interface ModalFormConfig {
  type: string;
  path?: string;
}
export interface ModalFormParams extends ESLToggleableActionParams {
  form: ModalFormConfig;
}

export interface ModalFormController {
  destroy(): void;
  isDescribedBy(params: ModalFormConfig): boolean;
}
export interface ModalFormControllerFactory {
  createForm($content: HTMLElement, params: ModalFormConfig): ModalFormController;
}
