import {attr, listen} from '@exadel/esl/modules/esl-utils/decorators';
import {HPEIframeModalTrigger} from 'design-3.0/hpe-iframe-modal.trigger';
import { HPEPathfactoryModalShared } from 'design-3.0/hpe-pathfactory-modal.shared';

export class HPEPathfactoryModalTrigger extends HPEIframeModalTrigger {
  public static is = 'hpe-pathfactory-modal-trigger';

  @attr({name: HPEPathfactoryModalTrigger.is})
  public directUrl: string;

  protected get url(): string {
    if (this.directUrl) return this.directUrl;
    if (this.$host instanceof HTMLAnchorElement) return this.$host.href;
    return '';
  }

  @listen('click')
  protected _onClick(e: Event): void {
    const $target = e.target as HTMLElement;
    if (!this.url || this.isIgnored($target)) return;

    e.preventDefault();
    e.stopPropagation();

    HPEPathfactoryModalShared.show({
      url: this.url,
      activator: this.$host
    });
  }
}
