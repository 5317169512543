import {unwrap} from 'core/helpers/dom';
import {getConfig} from 'core/helpers/config';
import {HPEPathFactoryURLUtils} from 'design-3.0/hpe-pathfactory.utils';
import {HPEIframeModalShared} from 'design-3.0/hpe-iframe-modal.shared';
import type {IframeModalActionParams} from 'design-3.0/hpe-iframe-modal';
import type {UtmParamConfigItem} from 'hpe-forms/form-utils';

export const DEFAULT_PF_UTM_PARAMS_CONFIG = [
  {name: 'utm_source', required: true},
  {name: 'utm_medium', required: true},
  {name: 'utm_campaign', required: true},
  {name: 'utm_content', required: true},
  {name: 'utm_term', required: false},
  {name: 'utm_geo', required: false},
  {name: 'crid', required: false},
  {name: 'plid', required: false}
];

export class HPEPathfactoryModalShared extends HPEIframeModalShared {
  public static preload($scope: JQuery<HTMLElement> | HTMLElement = document.body): void {
    if (unwrap($scope)?.querySelector('[hpe-pathfactory-modal-trigger]')) {
      HPEPathfactoryModalShared.init();
    }
  }

  public static show(params: IframeModalActionParams): void {
    const url = HPEPathFactoryURLUtils.appendUtmParams(params.url, HPEPathfactoryModalShared.getUtmParamsConfig());
    HPEPathfactoryModalShared.init().then(($modal) => $modal.show({...params, url}));
  }

  public static getUtmParamsConfig(): UtmParamConfigItem[] {
    return getConfig('pathfactory.pathfactoryUtmParamsConfig') || DEFAULT_PF_UTM_PARAMS_CONFIG;
  }
}
