import {HPEPathFactoryURLUtils} from 'design-3.0/hpe-pathfactory.utils';
import {HPEPathfactoryModalShared} from 'design-3.0/hpe-pathfactory-modal.shared';
import {HPEPathfactoryModalTrigger} from 'design-3.0/hpe-pathfactory-modal.trigger';

let _isReady = false;

export default {
  initialize: (): void => {
    if (_isReady) return;

    const url = HPEPathFactoryURLUtils.parse();
    url && HPEPathfactoryModalShared.show({url});

    HPEPathfactoryModalShared.preload();
    HPEPathfactoryModalTrigger.register();

    _isReady = true;
  }
};
