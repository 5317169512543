import * as React from 'jsx-dom';
import {ESLBaseElement} from '@exadel/esl/modules/esl-base-element/core';
import {boolAttr, listen, memoize, prop} from '@exadel/esl/modules/esl-utils/decorators';
import {promisifyEvent} from '@exadel/esl/modules/esl-utils/async';
import {ESC, SPACE, TAB} from '@exadel/esl/modules/esl-utils/dom/keys';
import {lockScroll, unlockScroll} from '@exadel/esl/modules/esl-utils/dom/scroll';

import Core from 'core/core';
import {error} from 'core/log';
import {i18nLabel} from 'core/helpers/config';
import {renderInfoModule} from 'core/video-info-module';
import VideoConfigLoader from 'core/video-config-loader';
import {VideoUtils} from 'core/video-utils';

import type {ESLMedia} from '@exadel/esl/modules/esl-media/core';
import type {VideoOverlayConfig} from 'core/video-overlay-types';

export class VideoOverlay extends ESLBaseElement {
  public static override is = 'video-overlay';

  @memoize()
  public static get instance(): VideoOverlay {
    const $this = this.create();
    $this.render();
    document.body.appendChild($this);
    return $this;
  }

  /**
   * Open video overlay using config
   * @param {VideoOverlayConfig} config
   * @return {Promise}
   * */
  public static open(config: VideoOverlayConfig): Promise<Event> {
    return this.instance.open(config);
  }

  /**
   * Close overlay
   * */
  public static close(): void {
    return this.instance.close();
  }

  /** Event to dispatch when overlay opened */
  @prop('video-overlay:open') public OPEN_EVENT: string;
  /** Event to dispatch when overlay closed */
  @prop('video-overlay:close') public CLOSE_EVENT: string;

  @boolAttr() public active: boolean;
  @boolAttr() public isAudio: boolean;
  @boolAttr() public loading: boolean;

  @memoize()
  protected get $closeBtn(): JSX.Element {
    return (
      <button className="icon-link close-video close-video-btn icon-nav-close-menu close-btn">
        <span className="sr-only">{i18nLabel('Close')}</span>
      </button>
    );
  }

  @memoize()
  protected get $content(): JSX.Element {
    return (
      <div className="video-container dark-theme">
        {this.$closeBtn}
        <div className="video-content img-container img-container-16-9">
          {this.$player}
        </div>
        {this.$videoInfoContainer}
      </div>
    );
  }

  @memoize()
  protected get $player(): ESLMedia {
    return (
      <smart-media media-src=""
                   group="hpe-embedded"
                   tabIndex={-1}
                   lazy="manual"
                   autoplay={true}
                   controls={true}
                   playsinline={true}
                   hva-video-tracker=""/>
    ) as ESLMedia;
  }

  protected get $shareButton(): HTMLElement {
    return this.$videoInfoContainer.querySelector('.share-button');
  }

  @memoize()
  protected get $videoInfoContainer(): JSX.Element {
    return (
      <div className="video-info-wrapper typo4"/>
    );
  }

  protected render(): void {
    this.appendChild(this.$content);
  }

  /**
   * @param {VideoOverlayConfig} options
   */
  public open(options: VideoOverlayConfig): Promise<Event> {
    if (!options || (!options.id || !options.type) && !options.resource) {
      error('[VideoOverlay] open takes config object param, id and type keys are required.');
      return Promise.reject();
    }

    this.active = true;
    this.loading = true;

    // Load content and show VideoOverlay
    this.loadAndStart(options);

    // Lock document scroll
    lockScroll(document.documentElement, {strategy: 'background', initiator: this});
    // Give initial focus to close button
    this.$closeBtn.focus();

    // Notify about state change
    this.$$fire(this.OPEN_EVENT);
    return promisifyEvent(this, this.CLOSE_EVENT);
  }

  public close(): void {
    // Pause ESLMedia instance
    this.$player.pause();

    // Unlock document scroll
    unlockScroll(document.documentElement, {initiator: this});
    // Remove open state marker from url
    VideoUtils.clearUrl();

    this.$$fire(this.CLOSE_EVENT);

    this.loading = false;
    this.active = false;
  }

  /** @return {Promise<VideoOverlayConfig>} */
  protected async loadResource(options: VideoOverlayConfig): Promise<VideoOverlayConfig> {
    if (!options.resource) return options;
    try {
      const data = await VideoConfigLoader.load(options.resource);
      return VideoUtils.mergeOptions(options, data);
    } catch (e) {
      return options;
    }
  }

  protected async loadAndStart(options: VideoOverlayConfig): Promise<void> {
    const opt = await this.loadResource(options);
    this.initAndStart(VideoUtils.applyDefaultOptions(opt));
  }

  /** @param {VideoOverlayConfig} options */
  protected initAndStart(options: VideoOverlayConfig): void {
    // Initialize
    this.setPlayerOptions(options);
    this.setInfoModuleContent(options);

    this.loading = false;
    this.isAudio = VideoUtils.isAudio(this.$player.mediaType);

    // Start ESLMedia instance initialization and play video
    this.$player.play(true);

    // Add open state marker to url
    VideoUtils.updateUrl(options);
  }

  /** @param {VideoOverlayConfig} options */
  protected setPlayerOptions(options: VideoOverlayConfig): void {
    const {$player} = this;
    $player.title = options.title;
    $player.mediaType = options.type;
    const {id} = options;
    if (id.startsWith('https://') || id.startsWith('http://') || id.startsWith('//')) {
      $player.mediaSrc = id;
    } else {
      $player.mediaId = id;
    }
    $player.startTime = options.startTime || 0;
    $player.$$attr('player-id', options.playerId);
    $player.$$attr('player-account', options.accountId);
    $player.$$attr('analytics-id', options.analyticsId);
  }

  protected setInfoModuleContent(options: VideoOverlayConfig): void {
    this.$videoInfoContainer.replaceChildren(renderInfoModule(options));
    Core.$compile(this);
  }

  /** @param {KeyboardEvent} e */
  protected handleTabKey(e: KeyboardEvent): void {
    const {target} = e;
    if (target === this.$closeBtn && e.shiftKey) {
      e.preventDefault();
      this.$shareButton.focus();
    }
    if (target === this.$shareButton && !e.shiftKey) {
      e.preventDefault();
      this.$closeBtn.focus();
    }
  }

  @listen('click')
  protected onCloseClick(e: MouseEvent): void {
    if (e.target !== this && e.target !== this.$closeBtn) return;
    this.close();
    e.stopImmediatePropagation();
    e.preventDefault();
  }

  @listen({
    event: 'keydown',
    target: window
  })
  protected onKeydown(e: KeyboardEvent): void {
    if (!this.active) return;
    switch (e.key) {
      case ESC:
        this.close();
        break;
      case SPACE:
        e.stopPropagation();
        e.preventDefault();
        this.$player.toggle();
        break;
      case TAB:
        return this.handleTabKey(e);
    }
  }
}

declare global {
  interface Window {
    VideoOverlayService: typeof VideoOverlay;
  }
}
window.VideoOverlayService = VideoOverlay;

export default VideoOverlay;
