import {ESLBaseElement} from '@exadel/esl/modules/esl-base-element/core';
import {attr, listen, memoize} from '@exadel/esl/modules/esl-utils/decorators';
import {isElement} from '@exadel/esl/modules/esl-utils/dom';
import {promisifyTimeout} from '@exadel/esl/modules/esl-utils/async/promise';
import {parseBoolean, toBooleanAttribute} from '@exadel/esl/modules/esl-utils/misc';

import Core from 'core/core';
import {error} from 'core/log';
import {getConfig} from 'core/helpers/config';
import PromiseUtils from 'core/helpers/promise-utils';

import SpinnerService from 'styleguide/spinner';

import {GenericModalService} from './generic-modal-service';

import type {BaseModal} from 'core/base-modal';
import type {ESLToggleableActionParams} from '@exadel/esl/modules/esl-toggleable/core';

export class GenericModalLoader extends ESLBaseElement {
  public static is = 'generic-modal-loader';

  /** Indirect modal wrapper type marker */
  @attr({parser: parseBoolean, serializer: toBooleanAttribute}) public indirect: boolean;

  protected _lastContentPath: string;
  protected triggerSearchParams?: Record<string, string> = {};

  /** Inner base-modal component reference */
  @memoize()
  public get $modal(): BaseModal | null {
    // Find first marked as base-modal component inside (see advanced-story case)
    return this.querySelector('.base-modal, base-modal');
  }

  /** Wrapper has no content inside */
  public get isEmpty(): boolean {
    return !Array.from(this.children).filter(isElement).length;
  }

  /** Identifier value for current modal to set into URL param */
  public get urlIdentifier(): string | null {
    if (!this.indirect) return this.id;
    return (this.triggerSearchParams || {})[GenericModalService.INDIRECT_MODAL_PARAM] || null;
  }

  /** URL parameter name, ether {@link GenericModalService.DIRECT_MODAL_PARAM} or {@link GenericModalService.INDIRECT_MODAL_PARAM} */
  public get urlParameter(): string {
    return this.indirect ? GenericModalService.INDIRECT_MODAL_PARAM : GenericModalService.DIRECT_MODAL_PARAM;
  }

  /** Base path for modal content */
  public get baseContentPath(): string {
    if (this.indirect) return this.triggerSearchParams[GenericModalService.INDIRECT_MODAL_PARAM];
    return this.$$attr('data-modal-path');
  }
  /** Last loaded content base path */
  public get lastContentPath(): string {
    return this._lastContentPath || this.baseContentPath;
  }

  /**
   * Appends content path request params to URL.
   * Uses all current search params + sourcePath and restricted wcmmode.
   */
  protected appendPathParams(url: string): string {
    const params = new URLSearchParams(window.location.search);
    params.set('sourcePage', getConfig('pagePath'));
    params.set('wcmmode', 'disabled');
    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}${params}`;
  }

  /** Clears modal content */
  public clearContent(): void {
    const $content = Array.from(this.children);
    this.innerHTML = '';
    this._lastContentPath = '';
    setTimeout(() => {
      $content.forEach((el) => Core.$registry.executeGC(el as HTMLElement));
    }, 1);
    memoize.clear(this, '$modal');
  }

  /** Loads content from contentPath */
  private async loadContent(): Promise<string> {
    const url = this.appendPathParams(this.baseContentPath);
    const response = await fetch(url);
    return await PromiseUtils.fetchText(response);
  }

  /** Updates modal content with passed HTML */
  private updateContent(content: string): void {
    this.clearContent();
    this.innerHTML = content;
    Core.$compile(this);
  }

  /** Updates URL history according to modal state */
  protected updateHistory(opened: boolean): void {
    const url = new URL(window.location.href);

    Object.entries(this.triggerSearchParams || {}).forEach(([param, value]) => {
      if (opened) url.searchParams.set(param, value);
      else url.searchParams.delete(param);
    });

    if (opened) url.searchParams.set(this.urlParameter, this.urlIdentifier);
    else url.searchParams.delete(this.urlParameter);

    window.history.replaceState(null, document.title, url.toString());
  }

  /** Opens modal with content from contentPath */
  public async open(): Promise<void> {
    if (!this.baseContentPath) return;
    if (this.isEmpty || this.lastContentPath !== this.baseContentPath) {
      try {
        SpinnerService.show({cls: 'hpe-spinner hpe-spinner-dark'});
        const [content] = await Promise.all([
          this.loadContent(), // Actual content fetch
          promisifyTimeout(200) // Min spinner time
        ]);
        this.updateContent(content);
        this._lastContentPath = this.baseContentPath;
      } catch (e) {
        error('Modal fetch failed: ', e);
        this.$$fire('esl:alert:show', {detail: {
          text: 'Failed to load content', cls: 'alert alert-danger'
        }});
      } finally {
        SpinnerService.hide();
      }
    }

    const options: Partial<ESLToggleableActionParams> = {initiator: 'loader'};
    if (document.activeElement instanceof HTMLAnchorElement) options.activator = document.activeElement;
    this.$modal?.show(options);
  }

  /** Checks if the modal should be opened according to passed params */
  public accept(params: Record<string, string>): boolean {
    // Modal requested directly by id
    if (this.id && params[GenericModalService.DIRECT_MODAL_PARAM] === this.id) return true;
    // Modal requested indirectly by url param (and accepts indirect input)
    return !!(this.indirect && params[GenericModalService.INDIRECT_MODAL_PARAM]);
  }

  /** Observes inner modal state changes */
  @listen('esl:show esl:hide')
  public _onModalStateChange(e: Event): void {
    if (e.target !== this.$modal) return;
    this.updateHistory(this.$modal.open);
  }

  /** Observes for global modal open requests */
  @listen({
    event: 'hpe:modal:open',
    target: document.body
  })
  protected _onOpenRequest(e: CustomEvent): void {
    if (!this.accept(e.detail)) return;
    this.triggerSearchParams = e.detail;
    this.open();
    e.stopImmediatePropagation();
    e.preventDefault();
  }

  /** Observes for modal close requests */
  @listen('hpe:modal:clear')
  protected _onClear(e: CustomEvent): void {
    e.stopImmediatePropagation();
    this.clearContent();
  }
}
